import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { PageProps } from '@lp-root/src/utils/local-types';
import { SleepDiaryPage } from '@component/sleep-diary/sleep-diary.page';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import WebviewLayout from '../../components/org.layout/webview-layout.component';

const DiarioDoSonoPage: React.FunctionComponent<PageProps> = (props) => {
  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Diário do sono',
          description: 'Ferramenta para acompanhamento do sono',
        }}
      />

      <SleepDiaryPage {...props} />
    </WebviewLayout>
  );
};
export default DiarioDoSonoPage;

export const query = graphql`
  query DiarioDoSonoPage {
    site {
      ...SiteUrl
    }
  }
`;
