import {
  mapBoolToYesNo,
  mapSleep_Tag_Enum_EnumToTagClass,
  SleepDiaryTag,
  SleepDiaryTechnique,
  TagClass,
} from '@global/utils/domain/entities';
import { SleepDiaryFormData, SleepDiaryFormDataWithIntervals } from '@global/utils/domain/sleep-diary';
import { getTechniquesInfo, SleepTechniqueInfo } from '@global/utils/domain/sleep-factor';
import {
  CustomWebviewMessageForSleepDiary,
  CustomWebviewMessageForSleepDiaryOuvirGravacoes,
} from '@global/utils/native/native-custom-webview-message';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import {
  Query_Root,
  Sleep_Diary,
  Sleep_Diary_Insert_Input,
  Sleep_Tag_Enum_Enum,
  Sleep_Tracker_Compressed_Data_Insert_Input,
  User,
} from '@global/utils/remote-graphql-types';
import { HasuraTimestamptz } from '@global/utils/remote-graphql-types.extra';
import { Body, Button, Col, FaIcon, FontSize, Grid, H1, H2, Row } from '@web/atomic';
import { FetchDataErrorPlaceholder, Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { TextShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { FormData } from '@web/atomic/legacy/obj.form';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { NativeHelper } from '@web/data/native.helper';
import onboardLogDatasource from '@web/data/onboard-log.datasource';
import React, { useCallback } from 'react';
import { closestNumberFromSortedArray } from './components/closest-number-from-sorted-array';
import { timeToSleepFieldOptions } from './components/time-to-sleep.component.model';
import { wakeUpDurationFieldOptions } from './components/wake-up-duration.component.model';
import { SleepDiaryForm } from './sleep-diary.component';
import {
  buildSleepMutationVar as buildSleepMutationInsertInput,
  DaysToFillItem,
  DaysToFillQueryParams,
  fixHourTimezone,
  getFormattedDuration,
  getSleepDiaryInfoFromPreviousDiaries,
  SleepDiaryFormInitialData,
  SleepDiaryFormSleepTrackerExtraData,
  useDaysToFillFromUser,
  useGetUserSleepDiary,
  useSleepDiaryDataFromWindow,
  useSleepDiaryMutation,
} from './sleep-diary.utils';

interface SleepDiaryPageProps {
  data?: {
    site?: {
      siteMetadata?: {
        siteUrl?: string;
      };
    };
  };
  location: Location;
}

export const SleepDiaryPage: React.FunctionComponent<SleepDiaryPageProps> = (props) => {
  const queryParams = useQueryParams<DaysToFillQueryParams>();
  const dataFromWindow = useSleepDiaryDataFromWindow();
  const onFetchCompleted = (result: Query_Root) => {
    const hasAccelerometerData = dataFromWindow?.intervals?.length > 0;
    const hasFilledSomeDiary = result.user[0]?.sleep_diaries.length > 0;
    const showFlash = !hasAccelerometerData && hasFilledSomeDiary && !onboardLogDatasource.hasSeenAutofill;
    if (showFlash) {
      flashDispatcherService.dispatchMessage(
        'Surpresa 🎉! Para ajudar você, eu vou sempre pré-preencher seu diário usando seus últimos sonos como referência.',
        'info'
      );
      onboardLogDatasource.hasSeenAutofill = true;
    }
  };
  const [user, userLoading, userError] = useGetUserSleepDiary({ onCompleted: onFetchCompleted }, queryParams?.force_day);

  const daysToFill: DaysToFillItem[] = useDaysToFillFromUser(user);

  // TODO: REFACTOR - extract placeholder to other file
  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();
  if (user && daysToFill.length === 0) {
    return (
      <Grid>
        <Row mt mb>
          <Col xs={12} sm={12} md={7} lg={7}>
            <Placeholder
              icon={<FaIcon.LaughWink size="9x" />}
              title={'Seu diário já está completamente preenchido!'}
              description={`Amanhã você poderá preenchê-lo novamente.`}
            >
              <Button onClick={close} loading={waitCloseLoading}>
                Combinado!
              </Button>
            </Placeholder>
          </Col>
        </Row>
      </Grid>
    );
  }

  if (userError) {
    return (
      <Grid>
        <Row mt mb>
          <Col xs={12} sm={12} md={7} lg={7}>
            <FetchDataErrorPlaceholder error={userError} />
          </Col>
        </Row>
      </Grid>
    );
  }

  return <SleepDiaryContainer fetchLoading={userLoading} user={user} daysToFill={daysToFill} dataFromWindow={dataFromWindow} />;
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ISleepDiaryContainerProps {
  user: User;
  dataFromWindow?: Partial<SleepDiaryFormDataWithIntervals>;
  daysToFill: DaysToFillItem[];
  fetchLoading: boolean;
}

const SleepDiaryContainer: React.FunctionComponent<ISleepDiaryContainerProps> = (props) => {
  const user = props.user;
  const daysToFill = props.daysToFill;
  const dataFromWindow = props.dataFromWindow;
  const autoFillInfo = getSleepDiaryInfoFromPreviousDiaries(user);

  const [submitSleepDiaryItem, submitLoading, submitData] = useSleepDiaryMutation(user);

  const techniques: SleepTechniqueInfo[] = user ? getTechniquesInfo(user).filter((factor) => factor.enable) : [];

  const handleSubmit = useCallback(
    async (formData: FormData<SleepDiaryFormData>) => {
      if (Object.keys(formData.error).length !== 0) return;
      const snoreData = dataFromWindow?.snoreData;
      const sleep_diary: Sleep_Diary_Insert_Input = await buildSleepMutationInsertInput(formData.data, techniques, snoreData);

      const sleep_tracker_compressed_data: Sleep_Tracker_Compressed_Data_Insert_Input =
        dataFromWindow?.intervals || dataFromWindow?.alarm || dataFromWindow?.compressData
          ? {
              user_id: sleep_diary.user_id,
              date: sleep_diary.date,
              intervals: dataFromWindow?.intervals,
              alarm: dataFromWindow?.alarm,
              compressed_data: dataFromWindow?.compressData,
              snore_data: snoreData?.times,
            }
          : null;

      const msg: CustomWebviewMessageForSleepDiary = {
        type: 'custom',
        id: 'sleep_diary',
        data: {
          sleepDiary: sleep_diary,
          medicineDoseList: formData.data.medicineDoseList,
        },
      };

      await Promise.all([
        NativeHelper.postMessage(msg),
        await submitSleepDiaryItem({
          sleep_diary,
          sleep_tracker_compressed_data,
        }),
      ]);
    },
    [submitSleepDiaryItem, techniques, dataFromWindow]
  );

  const handleHearRecordings = () => {
    const startDate = props.dataFromWindow.snoreData.start;
    const msg: CustomWebviewMessageForSleepDiaryOuvirGravacoes = {
      id: 'sleep_diary_recordings',
      type: 'custom',
      data: { startDate: new Date(startDate).toISOString() as HasuraTimestamptz },
    };
    NativeHelper.postMessage(msg);
  };

  const diaryAlreadyFilled = user?.sleep_diaries?.find((s) => props.daysToFill.find((d) => d.value === s.date));
  const hasAccelerometerData = dataFromWindow?.intervals?.length > 0;

  const sleepDiaryInitialData: SleepDiaryFormInitialData = getSleepDiaryFormInitialData(
    dataFromWindow,
    diaryAlreadyFilled,
    autoFillInfo,
    hasAccelerometerData
  );

  const sleepTrackerExtraData: SleepDiaryFormSleepTrackerExtraData = getSleepDiaryFormSleepTrackerExtraData(
    hasAccelerometerData,
    dataFromWindow
  );

  return (
    <Grid>
      <Row mt mb>
        <Col xs={12} sm={12} md={7} lg={7}>
          {hasAccelerometerData ? (
            <>
              <H2>Resultados do monitoramento</H2>
              <Body>
                Observe abaixo o registro do seu sono. Avalie se coincide com a sua percepção e faça ajuste nos horários se julgar
                necessário.
              </Body>
            </>
          ) : (
            <>
              <H1> Diário do sono</H1>
              {<Body>Olá {user ? user.first_name : <TextShimmerBoxStyled height={FontSize.Medium} width={'60px'} />}!</Body>}
              <Body>
                Preencha abaixo como foi seu sono. <br />
                Lembre-se: Os valores podem ser aproximados, não se preocupe em ser preciso. Uma estimativa boa o suficiente é o que
                precisamos aqui.
              </Body>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={7} lg={7}>
          <SleepDiaryForm
            user={user}
            key={`${isNullOrUndefined(autoFillInfo)}${isNullOrUndefined(dataFromWindow)}`}
            hasAccelerometerData={hasAccelerometerData}
            daysToFill={daysToFill}
            onSubmit={handleSubmit}
            fetchUserLoading={props.fetchLoading}
            submitData={submitData}
            submitLoading={submitLoading}
            techniques={techniques}
            initialData={sleepDiaryInitialData}
            sleepTrackerExtraData={sleepTrackerExtraData}
            medicineList={dataFromWindow?.medicineList ?? []}
            medicineDoseList={dataFromWindow?.medicineDoseList ?? []}
            snoreData={dataFromWindow?.snoreData}
            onHearRecordings={handleHearRecordings}
          />
        </Col>
      </Row>
    </Grid>
  );
};

function getSleepDiaryFormSleepTrackerExtraData(
  hasAccelerometerData: boolean,
  dataFromWindow: Partial<SleepDiaryFormDataWithIntervals>
): SleepDiaryFormSleepTrackerExtraData {
  return {
    wakeUpCount: hasAccelerometerData ? +dataFromWindow?.wakeUpCount : undefined,
    wakeUpDuration: hasAccelerometerData
      ? closestNumberFromSortedArray(wakeUpDurationFieldOptions, +dataFromWindow?.wakeUpDuration)
      : undefined,
  };
}

function getSleepDiaryFormInitialData(
  dataFromWindow: Partial<SleepDiaryFormDataWithIntervals>,
  diaryAlreadyFilled: Sleep_Diary,
  autoFillInfo: Partial<Sleep_Diary>,
  hasAccelerometerData: boolean
): SleepDiaryFormInitialData {
  const tags = diaryAlreadyFilled?.tags?.map((t) => t.sleep_tag);
  const tagsByClass = tags ? getSleepTagsByType(tags) : null;
  const techniques = diaryAlreadyFilled ? getSleepTechnique(diaryAlreadyFilled) : null;

  // DEV_TIP: if you need to set initial data, you can use the following code as a template
  // return {
  //   goBed: '00:03',
  //   goSleep: '00:10',
  //   timeToSleep: 20,
  //   wakeUpCount: 1,
  //   wakeUpDuration: 20,
  //   wakeUp: '05:30',
  //   getUp: '06:00',
  //   date: '2024-05-18',
  // }

  const response =  {
    tags1: dataFromWindow?.tags_1 || tagsByClass?.tags1,
    tags2: dataFromWindow?.tags_2 || tagsByClass?.tags2,
    techniques: dataFromWindow?.techniques || techniques,
    date: dataFromWindow?.date || diaryAlreadyFilled?.date,
    comment: dataFromWindow?.comment || diaryAlreadyFilled?.comment,
    medicine: dataFromWindow?.medicine || mapBoolToYesNo(diaryAlreadyFilled?.medicine) || mapBoolToYesNo(autoFillInfo?.medicine),
    grade: dataFromWindow?.grade || diaryAlreadyFilled?.grade,
    relationships: dataFromWindow?.relationships || diaryAlreadyFilled?.relationships,
    concentration: dataFromWindow?.concentration || diaryAlreadyFilled?.concentration,
    energy: dataFromWindow?.energy || diaryAlreadyFilled?.energy,
    humor: dataFromWindow?.humor || diaryAlreadyFilled?.humor,

    goBed: dataFromWindow?.goBed || fixHourTimezone(diaryAlreadyFilled?.go_bed) || (autoFillInfo && fixHourTimezone(autoFillInfo.go_bed)),
    goSleep:
      dataFromWindow?.goSleep || fixHourTimezone(diaryAlreadyFilled?.go_sleep) || (autoFillInfo && fixHourTimezone(autoFillInfo.go_sleep)),
    timeToSleep: hasAccelerometerData
      ? closestNumberFromSortedArray(timeToSleepFieldOptions, +dataFromWindow?.timeToSleep)
      : diaryAlreadyFilled?.time_to_sleep >= 0
      ? closestNumberFromSortedArray(timeToSleepFieldOptions, getFormattedDuration(diaryAlreadyFilled?.time_to_sleep))
      : autoFillInfo && closestNumberFromSortedArray(timeToSleepFieldOptions, getFormattedDuration(autoFillInfo.time_to_sleep)),
    wakeUpCount: hasAccelerometerData
      ? +dataFromWindow?.wakeUpCount
      : diaryAlreadyFilled?.wake_up_count >= 0
      ? diaryAlreadyFilled.wake_up_count
      : autoFillInfo && getFormattedDuration(autoFillInfo.wake_up_count),
    wakeUpDuration: hasAccelerometerData
      ? closestNumberFromSortedArray(wakeUpDurationFieldOptions, +dataFromWindow?.wakeUpDuration)
      : diaryAlreadyFilled?.wake_up_duration ||
        (autoFillInfo && closestNumberFromSortedArray(wakeUpDurationFieldOptions, getFormattedDuration(autoFillInfo.wake_up_duration))),
    wakeUp:
      dataFromWindow?.wakeUp || fixHourTimezone(diaryAlreadyFilled?.wake_up) || (autoFillInfo && fixHourTimezone(autoFillInfo.wake_up)),
    getUp: dataFromWindow?.getUp || fixHourTimezone(diaryAlreadyFilled?.get_up) || (autoFillInfo && fixHourTimezone(autoFillInfo.get_up)),
  };
  return response
}

interface Tags {
  tags1: SleepDiaryTag[];
  tags2: SleepDiaryTag[];
}

const getSleepTagsByType = (tagsArray: Sleep_Tag_Enum_Enum[]): Tags => {
  const tags1 = tagsArray.filter((tag) => mapSleep_Tag_Enum_EnumToTagClass(tag) === TagClass.afterSleep) as unknown as SleepDiaryTag[];
  const tags2 = tagsArray.filter((tag) => mapSleep_Tag_Enum_EnumToTagClass(tag) === TagClass.beforeSleep) as unknown as SleepDiaryTag[];
  return {
    tags1,
    tags2,
  };
};

const getSleepTechnique = (SleepDiary: Sleep_Diary): SleepDiaryTechnique[] => {
  const techniques = [];
  [
    SleepDiaryTechnique.ThoughtBlock,
    SleepDiaryTechnique.StimulusControl,
    SleepDiaryTechnique.ParkingLot,
    SleepDiaryTechnique.LightTherapy,
    SleepDiaryTechnique.ChallengeCatastrophicThinking,
    SleepDiaryTechnique.Gratitude,
    SleepDiaryTechnique.ParadoxicalIntention,
    SleepDiaryTechnique.Meditation,
    SleepDiaryTechnique.PMR,
    SleepDiaryTechnique.DeepBreath,
    SleepDiaryTechnique.AutgenicTraining,
    SleepDiaryTechnique.Imagery,
  ].forEach((technique) => {
    if (SleepDiary[technique]) techniques.push(technique);
  });
  return techniques;
};
