import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';

/**
 * src: https://stackoverflow.com/a/25087661/3670829
 **/
export function closestNumberFromSortedArray(arr: number[], target: number) {
  if (isNaN(target) || isNullOrUndefined(arr) || isNullOrUndefined(target) || arr.length == 0) return null;
  if (arr.length == 1) return arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > target) {
      const p = arr[i - 1];
      const c = arr[i];
      return Math.abs(p - target) < Math.abs(c - target) ? p : c;
    }
  }
  return arr[arr.length - 1];
}
